import "jquery";
import "jquery.cookie";
import "bootstrap";

import "../scss/storefront.scss";

import "./components/misc";
import "./components/address-form";
import "./components/checkout";
import "./components/navbar";
import "./components/password-input";
import "./components/product-filters";
import "./components/sorter";
import "./components/styleguide";
import "./components/product-detail";
import "./components/search-field";
import "./components/faq";
import "./components/slot-expired";
import "./components/facebook-chatbot";
import "./components/whatsapp-ballon";

if (document.querySelector("[data-gateway-form]")) {
  import("./components/summary").then(({ default: summary }) => {
    const { gatewayForm } = document.querySelector(
      "[data-gateway-form]"
    ).dataset;
    summary[gatewayForm]();
    console.info("summary success");
  });
}
if (document.querySelector("[data-feature=adyen]")) {
  import("./components/adyen").then(({ default: init }) => {
    console.info("adyen");
    init();
  });
}
if (document.getElementById("not_in_stock")) {
  import("./components/no-in-stock").then(() => {
    console.info("no-in-stock success");
  });
}
if (document.getElementById("adyen_paypal_container")) {
  import("./components/paypal-adyen").then(() => {
    console.info("paypal-adyen");
  });
}
