export default $(document).ready(() => {
  $(".faq .faq-container .faq-question .faq-question__title").click(
    function () {
      const header = $(this);

      const arrow = header.find("svg");
      const content = header.next(".faq-question__content");

      content.slideToggle(300);
      arrow.toggleClass("rotated");
    }
  );
});
