const ENV_COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE;

document.addEventListener("DOMContentLoaded", () => {
  const checkoutActionPayElements = document.querySelectorAll(".action-pay");
  const checkoutTotalElement = document.getElementById("checkoutTotal");
  const checkoutNotesAreaElement = document.getElementById(
    "checkout-notes-area"
  );
  const checkoutNotesStorage = sessionStorage.getItem("justoCheckoutNote");
  const minModalElement = document.getElementById("min_amount_modal");

  checkoutActionPayElements?.forEach((element) => {
    element?.addEventListener("click", () => {
      const { minAmount } = element.dataset;

      // Keep only numbers, "points" and "commas".
      const [totalLabel] = checkoutTotalElement.innerText.match(
        /([0-9]+[,\\.]?)+/g,
        ""
      );

      let totalAmount;
      if (ENV_COUNTRY_CODE === "BR") {
        totalAmount = totalLabel.replace(".", "").replace(",", ".");
      } else {
        totalAmount = totalLabel.replace(",", "");
      }

      totalAmount = Number(totalAmount);

      if (totalAmount < minAmount) {
        minModalElement.style.display = "block";
        event.preventDefault();
        return;
      }

      element.setAttribute("data-spinner-disabled", true);
      element.setAttribute("disabled", true);
    });
  });

  minModalElement
    ?.querySelector("#close_modal")
    ?.addEventListener("click", () => {
      minModalElement.style.display = "none";
    });
  checkoutNotesAreaElement?.addEventListener("change", ({ target: value }) =>
    sessionStorage.setItem("justoCheckoutNote", value)
  );

  if (checkoutNotesAreaElement?.value) {
    checkoutNotesAreaElement.value = checkoutNotesStorage;
  }
});
