$(document).ready(() => {
  const removeButton = $("#productDetailRemove");
  const addButton = $("#productDetailAdd");
  const quantityInput = $("#id_quantity");
  const removeButtons = $(".productDetailRemove");
  const addButtons = $(".productDetailAdd");

  removeButton.click((e) => {
    e.preventDefault();
    const newValue = parseInt(quantityInput.val(), 10) - 1;
    if (newValue) {
      quantityInput.val(newValue);
    }
  });

  addButton.click((e) => {
    e.preventDefault();
    const newValue = parseInt(quantityInput.val(), 10) + 1;
    if (newValue < 51) {
      quantityInput.val(newValue);
    }
  });

  addButtons.click((e) => {
    e.preventDefault();
    const sibilingInput = $(e.target).prev();
    const newValue = parseInt(sibilingInput.val(), 10) + 1;
    if (newValue < 51) {
      sibilingInput.val(newValue);
      sibilingInput.trigger("change");
    }
  });

  removeButtons.click((e) => {
    e.preventDefault();
    const sibilingInput = $(e.target).next();
    const newValue = parseInt(sibilingInput.val(), 10) - 1;
    if (newValue) {
      sibilingInput.val(newValue);
      sibilingInput.trigger("change");
    }
  });
});
