export default $(document).ready(() => {
  const isCheckout =
    ($("#checkout-header").length > 0 ||
      document.location.href.includes("checkout")) &&
    !document.location.href.includes("checkout-success");
  const chat = document.getElementById("fb-customerchat");

  if (!isCheckout && !!chat) {
    const { pageId, appId } = chat.dataset;

    chat.className = "fb-customerchat";
    chat.setAttribute("page_id", pageId);
    chat.setAttribute("ref", "");

    window.fbMessengerPlugins = window.fbMessengerPlugins || {
      init: function () {
        FB.init({
          appId: appId,
          autoLogAppEvents: true,
          xfbml: true,
          version: "v3.3",
        });
      },
      callable: [],
    };
    window.fbAsyncInit =
      window.fbAsyncInit ||
      function () {
        window.fbMessengerPlugins.callable.forEach(function (item) {
          item();
        });
        window.fbMessengerPlugins.init();
      };
    setTimeout(function () {
      (function (d, s, id) {
        var js;
        var fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/es_LA/sdk/xfbml.customerchat.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }, 0);
  } else {
    $(chat).remove();
  }
});
