import passwordInvisible from "../../images/pass-invisible.svg";
import passwordVisible from "../../images/pass-visible.svg";

export default $(document).ready(() => {
  $(".passIcon").attr("src", passwordInvisible);
  $(".passIcon").on("click", (e) => {
    const $input = $(e.target).closest(".input-group").find("input");
    if ($input.attr("type") === "password") {
      $input.attr("type", "text");
      $(e.target).attr("src", passwordVisible);
    } else {
      $input.attr("type", "password");
      $(e.target).attr("src", passwordInvisible);
    }
  });
});
