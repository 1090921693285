import debounce from "lodash/debounce";

export default $(document).ready(() => {
  const field = $(".search-field__container input[type=search]");
  const form = field.closest("form");
  const list = form.find("#headerSearchBarResponse");
  const icon = $(".search-field__container #clean-search");
  const value = field.val();

  $(document).on("click", function ({ target }) {
    !field.is(target) && !list.is(":hidden") && list.fadeOut();
  });

  const onClickSearch = function (event) {
    const items = list.find("ul li");
    if (items.length > 0) {
      list.fadeIn();
    } else {
      onSearchProducts(event);
    }
  };

  const onSelectProduct = function ({ target }) {
    const { value } = target.dataset;
    field.val(value);
    form.submit();
  };

  const onSearch = function (event) {
    if (event.which === 13 || event.keyCode === 13) {
      onSearchProducts.cancel();
      list.fadeOut();
      return true;
    } else {
      if (!icon.is(":hidden")) {
        icon.hide();
      }
      onSearchProducts(event);
    }
  };

  const onSearchProducts = debounce(function ({ target }) {
    const { value } = target;

    if (value.replace(/\s/g, "") !== "") {
      fetch(`/search/autocomplete/?q=${value}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then(({ suggestions }) => {
          const items = (suggestions || []).map((suggestion) => {
            const li = document.createElement("li");
            const pattern = `(${value.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&")})`;

            const text = suggestion.value
              .replace(new RegExp(pattern, "gi"), "<strong>$1</strong>")
              .replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(/"/g, "&quot;")
              .replace(/&lt;(\/?strong)&gt;/g, "<$1>");

            li.dataset.value = suggestion.value;
            li.innerHTML = text;
            li.onclick = onSelectProduct;

            return li;
          });

          if (items.length > 0) {
            list.fadeIn().find("ul").html(items);
          } else {
            list.fadeOut(() => {
              list.find("ul").html(items);
            });
          }
        })
        .catch((error) => {
          console.warn("error: ", error);
        });
    } else {
      list.fadeOut(() => {
        list.find("ul").html("");
      });
    }
  }, 500);

  if (!!value && value.length > 0) {
    icon.show();

    icon.on("click", () => {
      field.val("");
      icon.hide();
      list.fadeOut(() => {
        list.find("ul").html("");
      });
    });
  }

  field.on("click", onClickSearch);
  field.on("keyup cut paste", onSearch);
});
