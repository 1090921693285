import "lazysizes";
import FontFaceObserver from "fontfaceobserver";
import debounce from "lodash/debounce";
import SVGInjector from "svg-injector-2";
import Cookies from "js-cookie";
import { trackSignUpEvent } from "@justomx/common/dist/utils/analytics2";

const csrfSafeMethod = (method) => {
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
};

export default document.addEventListener("DOMContentLoaded", () => {
  let tabLink;
  let tabContent;
  const productImages = document.querySelectorAll(".product-image");
  const svgs = document.querySelectorAll("svg[data-src]");
  const spinnerButtons = document.querySelectorAll(
    "[data-spinner-disabled][disabled]"
  );
  const { hash } = window.location;

  new SVGInjector().inject(svgs);

  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
        const csrftoken = Cookies.get("csrftoken");
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
      }
    },
  });

  if (hash) {
    try {
      tabLink = document.querySelector(
        `.nav-link[data-toggle="tab"][href="${hash}"]`
      );
      tabContent = document.querySelector(hash);
    } catch (error) {
      console.warn("Not exist tab");
    }
  }

  // Open tab from the link
  if (tabLink && tabContent) {
    try {
      const activeTabLink = document.querySelector(
        '.nav-link.active[data-toggle="tab"]'
      );
      const activeTabContent = document.querySelector(
        '.tab-pane.active.show[role="tabpanel"]'
      );
      if (activeTabContent) {
        activeTabContent.classList.remove("active", "show");
      }
      if (activeTabLink) {
        activeTabLink.classList.remove("active");
      }
      tabLink.classList.add("active");
      tabContent.classList.add("active", "show");
    } catch (error) {
      console.warn("Not exist tab");
    }
  }

  // Preload all images
  window.lazySizesConfig = window.lazySizesConfig || {};
  window.lazySizesConfig.preloadAfterLoad = true;

  // Update product-image height
  if (productImages.length) {
    const updateProductImageHeight = debounce(() => {
      if (productImages.length) {
        const productImage = productImages[0];
        productImages.forEach((image) => {
          const width = productImage.clientWidth;
          image.style.height = `${width}px`;
        });
      }
    }, 300);
    updateProductImageHeight();
    window.addEventListener("resize", updateProductImageHeight);
  }

  spinnerButtons.forEach((button) => {
    button.removeAttribute("data-spinner-disabled");
    button.removeAttribute("disabled");
  });

  const laloFonts = new FontFaceObserver("Lato");
  laloFonts.load().then(() => {
    document.documentElement.classList.add("fonts-loaded");
  });

  const userSignedCookie = Cookies.get("user_signed_event");

  if (userSignedCookie) {
    const [type, source] = userSignedCookie.split("|"); // "tracking_type|source"
    trackSignUpEvent({ type, source });
    Cookies.remove("user_signed_event");
  }
});
