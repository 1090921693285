export const show = (element) => {
  const whatsapp = element ? $(element) : $("#order-whatsapp__help[data-show]");

  if (whatsapp.length > 0) {
    whatsapp.fadeIn(() => {
      const closed = whatsapp.find(".whatsapp-ballon__closed");
      closed.click(() => {
        const ballon = whatsapp.find(".whatsapp-ballon");
        ballon.fadeOut(() => {
          ballon.remove();
        });
      });
    });
  }
};

export default $(document).ready(() => show());
